

















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import SdWeatherIcon from "../SdWeatherIcon";

export type WeatherData = {
	id: number;
	temp: number;
};

@Component({
	components: { SdWeatherIcon },
})
export default class Weather extends Vue {
	/**
	 * Weather data to display as current.
	 */
	@Prop({ default: null })
	current!: WeatherData;

	/**
	 * Weather forecast data to display for tomorrow.
	 */
	@Prop({ default: null })
	tomorrow!: WeatherData;

	/**
	 * Weather forecast data to display for the day after tomorrow.
	 */
	@Prop({ default: null })
	dayAfterTomorrow!: WeatherData;

	get isMinusDegrees() {
		return this.current.temp < 0;
	}

	get isNight() {
		const time = new Date();

		// TODO: Calculate in sunset / sunrise.

		return time.getHours() < 6;
	}

	get dayAfterTomorrowDate(): Date {
		const afterTomorrow = new Date();

		afterTomorrow.setDate(afterTomorrow.getDate() + 2);

		return afterTomorrow;
	}
}
