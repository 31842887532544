import http from "@/http";

export class TransitTimesService {
	static async getDepartures(stops: any[]) {
		try {
			const ids: string[] = stops.map((s: any) => s.stopId);
			const qs = ids.join("&stop[]=");
			const res = await http.get(`transit/departures/?stop=${qs}`);

			if (res && res.status === 200) {
				return res.data;
			}
		} catch (err) {
			// Do nothing yet.
		}
	}
}
