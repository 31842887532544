import { IsBoolean, IsDate, IsEnum, IsNotEmpty, IsOptional, IsString } from "class-validator";

import { NoteStatus } from "./interfaces";

export class CreateNoteDto {
	@IsString()
	@IsNotEmpty()
	text!: string;

	@IsOptional()
	@IsEnum(NoteStatus)
	status!: NoteStatus;

	@IsOptional()
	@IsDate()
	date!: Date;

	@IsOptional()
	@IsBoolean()
	sticky!: boolean;
}
