import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import Vue from "vue";

Sentry.init({
	dsn:
		"https://18c2b69d6a94407fbc3a1d23d99b08ff@o224998.ingest.sentry.io/5519690",
	environment: process.env.NODE_ENV,
	integrations: [
		new VueIntegration({
			Vue,
			tracing: true,
		}),
		new Integrations.BrowserTracing(),
	],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
});
