import Vue, { PluginObject } from "vue";

import * as Sd from "./Sd";

function nameToTag(name: string) {
	return name
		.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`)
		.replace(/^-/, "");
}

export const VueScrinzComponents: PluginObject<any> = {
	install: V => {
		for (const name of Object.keys(Sd)) {
			const tag = nameToTag(name);
			const component: Function = (Sd as any)[name];

			if (
				typeof component === "function" &&
				component.prototype instanceof Vue
			) {
				V.component(tag, component);
			}
		}
	},
};
