




































import { Component, Prop, Vue } from "vue-property-decorator";

import { encodeHashid } from "@scrinz/helpers";
import SdQrCode from "../SdQrCode";

@Component({
	components: {
		SdQrCode,
	},
})
export default class SdNoteBoardInfo extends Vue {
	@Prop({ required: true })
	manifest!: any;

	@Prop({})
	smsNumber!: string;

	@Prop({ default: "default" })
	logo!: string;

	@Prop()
	displayUrl!: string;

	get previewLink() {
		const hashId = encodeHashid("display", this.manifest.entity.id);

		return `${window.location.origin}/preview/${hashId}`;
	}
}
