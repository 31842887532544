






























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { SdImageLoader } from "../SdImageLoader";
import { SdTemplateContainer } from "../SdTemplateContainer";

function fixDates(slides: any[]) {
	return slides.map((slide: any) => {
		slide.start = new Date(slide.start);
		slide.end = new Date(slide.end);

		return slide;
	});
}

function filterActiveSlides(slides: any[]) {
	const now = new Date();

	return fixDates(slides).filter(slide => {
		// tslint:disable
		return true // slide.asset
			// && slide.asset.hasCropped
			&& (slide.start <= now && slide.end >= now)
			;
	});
}

@Component({
	components: { SdImageLoader, SdTemplateContainer },
})
export default class SdSlider extends Vue {
	@Prop({ default: "/", type: String })
	assetsBasePath!: string;

	@Prop({ default: () => [], type: Array })
	slides!: any[];

	@Prop({ default: () => [], type: Array })
	fallbackSlides!: [];

	@Prop({ default: "", type: String})
	fallbackText!: string;

	@Prop({ default: true, type: Boolean })
	showFallbackText!: boolean;

	@Prop({ default: () => ({}) })
	templateVariables!: any;

	templates: any[] = [];
	activeSlides: any[] = [];
	currentSlide = 0;
	timeout!: any;

	@Watch("slides", { immediate: true })
	@Watch("fallbackSlides")
	onSlidesChange() {
		this._resetSlider();
	}

	get showFallback() {
		return this.activeSlides.length === 0
			&& this.showFallbackText
			&& this.fallbackText;
	}

	/**
	 * Returns true if `index` matches the current slide.
	 */
	isCurrentSlide(index: number) {
		return index === this.currentSlide;
	}

	// Returns true if `index` is the next in line to be displayed.
	isNextSlide(index: number) {
		if (index === 0 && this.currentSlide + 1 === this.activeSlides.length) {
			return true;
		}

		return index - 1 === this.currentSlide;
	}

	// Returns true if `index` was the previous slide displayed.
	isPreviousSlide(index: number) {
		if (index + 1 === this.activeSlides.length && this.currentSlide === 0) return true;

		return index + 1 === this.currentSlide;
	}

	getClassesForSlide(index: number) {
		return {
			"sd-slider__slide--active": this.isCurrentSlide(index),
			"sd-slider__slide--next": this.isNextSlide(index),
			"sd-slider__slide--previous": this.isPreviousSlide(index),
		};
	}

	getImageSrc(template: any) {
		let image = template.asset ? template.asset.id : template.image;
		const cropped = template.asset && template.asset.hasCropped;

		return `${this.assetsBasePath}/${image}${cropped ? "/cropped" : ""}`;
	}

	/**
	 * Rotates the slider to the next slide, resetting the slider when reaching
	 * the end.
	 */
	rotate() {
		// Updates list of active slides if we're done rotating through all
		// currently active slides.
		if (this.currentSlide + 1 >= this.activeSlides.length) {
			this._resetSlider();
		}
		// Else just increment `currentSlide` with 1 and set rotation timeout.
		else {
			this.currentSlide++;
			this._setRotationTimeout();
		}
	}

	/**
	 * Updates the currently active slides by filtering them and testing the
	 * start and end dates are before and after (respectively) the current time.
	 */
	private _resetSlider() {
		// Get active slides from all slides.
		const activeSlides = filterActiveSlides(this.slides);

		// Set active slides, using fallback slides if there are no active.
		this.activeSlides
			= activeSlides.length > 0
			? activeSlides
			: filterActiveSlides(this.fallbackSlides || [])
			;

		// Reset current slide to zero.
		this.currentSlide = 0;

		// Start rotation timeout.
		this._setRotationTimeout();
	}

	// Sets the timeout for when to next rotate.
	private _setRotationTimeout() {
		clearTimeout(this.timeout);

		if (this.activeSlides.length <= 0) return;

		this.timeout = setTimeout(
			this.rotate.bind(this),
			this.activeSlides[this.currentSlide].duration * 1000,
		);
	}
}
