









































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment-timezone";

import { SdSlideTimer } from "../SdSlideTimer";

import { ONE_MINUTE, RUTER_COLORS, TRANSIT_TIMES_THEME } from "./constants";
import {
	SdTransitTimesDepartureInterface,
	SdTransitTimesTransitTimesType,
} from "./interfaces";

@Component({
	components: { SdSlideTimer },
})
export default class SdTransitTimes extends Vue {
	/**
	 * Required property defining the stops and departures to be displayed.
	 *
	 * Expects value to be either `null`, indicating that it's loading; `false`,
	 * indicating the loading has failed and to display an error message; or an
	 * array of `SdTransitTimesStopInterface`.
	 */
	@Prop({ default: null, type: [Boolean, Object, Array] })
	transitTimes!: SdTransitTimesTransitTimesType;

	started = false;
	currentStop = 0;

	get numStops() {
		return this.transitTimes && this.transitTimes instanceof Array
			? this.transitTimes.length
			: 0;
	}

	@Watch("transitTimes", { immediate: true })
	_onTransitTimesChange() {
		if (this.transitTimes) {
			this.started = true;
		}
	}

	isCurrent(index: number) {
		return index === this.currentStop;
	}

	isNext(index: number) {
		return (
			(index === 0 && this.numStops === this.currentStop + 1) ||
			this.currentStop === index - 1
		);
	}

	isPrevious(index: number) {
		return (
			(this.numStops === index + 1 && this.currentStop === 0) ||
			this.currentStop === index + 1
		);
	}

	getStopClassFor(index: number) {
		return {
			"sd-transit-times__stops__stop--current": this.isCurrent(index),
			"sd-transit-times__stops__stop--next": this.isNext(index),
			"sd-transit-times__stops__stop--previous": this.isPrevious(index),
		};
	}

	getDeparturesForStop(stop: any) {
		return stop.departures.map((departure: any) => ({
			...departure,
			fromNow: this.departureTimeToMinutes(departure.departureTime),
		}));
	}

	getIconStylingForJourney(journey: SdTransitTimesDepartureInterface) {
		/**
		 * Code is a little hacky and/or messy but the intention is to make the
		 * separate functions silently fail when any properties are undefined, so as
		 * to only style parts that are available while leaving anything else
		 * untouched
		 */
		const bgColor: string = this.getTransportModeColor(
			journey.transportMode,
			journey.lineCode
		)!;
		const bgSize = this.getLineCodeIconSize(journey.lineCode)!;
		const bgWidth: string = bgSize && bgSize.width;
		const bgRadius: string = bgSize && bgSize.radius;

		return {
			"background-color": bgColor,
			width: `${bgWidth}px`,
			"border-radius": `${bgRadius}%`,
		};
	}

	getTransportModeColor(transportMode: string, lineCode: string) {
		if (!transportMode || !lineCode) return;

		switch (transportMode) {
			case "metro":
				return RUTER_COLORS.metro;
				break;
			case "bus":
				if (lineCode[0] === "F" && lineCode[1] === "B")
					return RUTER_COLORS.bus.airport;
				else if (parseInt(lineCode) >= 100) return RUTER_COLORS.bus.district;
				else return RUTER_COLORS.bus.city;
				break;
			case "water":
				return RUTER_COLORS.water;
				break;
			case "rail":
				return RUTER_COLORS.rail;
				break;
			case "tram":
				return RUTER_COLORS.tram;
				break;
			default:
				return "";
				break;
		}
	}

	getLineCodeIconSize(lineCode: string) {
		if (!lineCode) return;

		const sizes = TRANSIT_TIMES_THEME.iconSizes;

		if (lineCode.length <= 2) return sizes.round;
		else if (lineCode.length === 3) return sizes.small;
		else if (lineCode.length === 4) return sizes.medium;
		else if (lineCode.length >= 5) return sizes.large;

		return;
	}

	onSlideChanged(slide: number) {
		this.currentStop = slide;
	}

	departureTimeToMinutes(time: string | moment.Moment) {
		time = moment(time);

		const now = moment();
		const diff = time.diff(now);

		return Math.floor(diff / ONE_MINUTE);
	}
}
