import "reflect-metadata";

export abstract class BaseDto<T extends { [key: string]: any } = {}> {
	constructor(data?: T) {
		if (!data) return;

		Object.keys(data).forEach(
			key => ((this as any)[key] = data[key])
		);
	}
}
