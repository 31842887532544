export enum EntityType {
	Group,
	Organization,
	Display,
}

export interface EntityInterface {
	id?: number;
	name: string;
	entityType: EntityType;
	codeWord?: string;
	// parentId: number|null;
	parent?: EntityInterface;
	children?: EntityInterface[];
}
