import getenv from "getenv.ts";
import merge from "lodash/merge";
import Vue, { VueConstructor } from "vue";

export interface BootstrapVueOptions {
	additionalConstructorProperties: any;
	router?: any;
	store?: any;
	mountElement: string;
	waitForWindowLoad: boolean;
	developmentOptions: {
		performance: boolean;
		productionTip: boolean;
	};
}

const DEFAULT_OPTIONS: BootstrapVueOptions = {
	additionalConstructorProperties: {},
	mountElement: "#app",
	waitForWindowLoad: false,
	developmentOptions: {
		performance: true,
		productionTip: false,
	},
};

// Bootstraps a Vue component, with some extra logging niceties for development.
export async function bootstrapVue(
	vue: typeof Vue,
	name: string,
	mainComponent: VueConstructor,
	options: Partial<BootstrapVueOptions> = {},
): Promise<Vue> {
	// Setup defaults.
	const opts: BootstrapVueOptions = merge(DEFAULT_OPTIONS, options);

	const { router, store } = opts;
	const NODE_ENV = getenv.string("NODE_ENV");

	// Setup global variables in development environment.
	if (NODE_ENV === "development") {
		// Disable production tip, as that's a given with the other outputs.
		vue.config.productionTip = opts.developmentOptions.productionTip;

		// Enable User Timing API for Vue for performance metrics.
		vue.config.performance = opts.developmentOptions.performance;

		// tslint:disable:no-console
		console.log(`%c${name} starting in "${NODE_ENV}"`, "background: white; color: teal; font-size: x-large;");

		if (process.env) {
			(window as any).$env = process.env;
			console.log(`%cEnvironment variables:`, "background: white; color: orange; font-size: large;", process.env);
		}

		if (router) {
			(window as any).$router = router;
			console.log(`%cRouter options:`, "background: white; color: orange; font-size: large;", router.opts);
		}

		if (store) {
			(window as any).$store = store;
			console.log(`%cStore getters:`, "background: white; color: orange; font-size: large;", store.getters);
		}
		// tslint:enable:no-console
	}

	// Initiates and mounts main component.
	function initMainComponent() {
		return new mainComponent({
			router,
			store,
			...opts.additionalConstructorProperties,
		} as any).$mount(opts.mountElement);
	}

	// Setup main component on window load.
	async function bindToWindowLoad() {
		return new Promise<Vue>(resolve => {
			window.addEventListener("load", () => {
				resolve(initMainComponent());
			});
		});
	}

	// Function to use for initiation, wait for load or init instantly.
	const bindOrInit = opts.waitForWindowLoad ? bindToWindowLoad : initMainComponent;

	// Initiate the store if supplied and bind to window load.
	return store ? store.dispatch("init").then(bindOrInit) : bindOrInit();
}
