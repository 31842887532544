










import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { DisplayOrientation } from "@scrinz/dtos";

@Component({})
export default class AbsoluteLayout extends Vue {
	@Prop({ required: true, type: [Number] })
	orientation!: DisplayOrientation;

	@Prop({ default: false })
	demo!: boolean;

	@Prop({ default: false })
	hideModules!: boolean;

	get styleClasses() {
		return {
			colors: this.demo,
			"hide-modules": this.hideModules,
			horizontal: this.orientation === DisplayOrientation.Horizontal,
			vertical: this.orientation === DisplayOrientation.Vertical,
		};
	}
}
