import { IsBoolean, IsEnum, IsNumber, IsString } from "class-validator";

import { BaseDto } from "..";
import { AssetType } from "./interfaces";

export class AssetDto extends BaseDto {
	@IsString() id!: string;
	@IsString() name!: string;
	@IsString() mime!: string;
	@IsNumber() bytes!: number;
	@IsEnum(AssetType) type!: AssetType;

	@IsNumber() page!: number;
	@IsNumber() offsetX!: number;
	@IsNumber() offsetY!: number;
	@IsNumber() scale!: number;
	@IsBoolean() hasCropped!: boolean;
}
