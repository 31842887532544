import { IsEmail, IsEnum, IsInt } from "class-validator";

import { BaseDto, UserRole } from "..";

export class EntityUserDto extends BaseDto {
	@IsInt() userId!: number;

	@IsEmail() email!: string;

	@IsEnum(UserRole) role!: UserRole;
}
