import getenv from "getenv.ts";
import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import { RootState } from "./types";
export * from "./types";

import DebugStore from "./debug";
export * from "./debug";
import InstallationStore from "./installation";
export * from "./installation";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
	state: {
		version: "1.0.0",
	},
	modules: {
		InstallationStore,
	},
};

if (getenv.string("NODE_ENV") === "development") {
	(store.modules as any)["DebugStore"] = DebugStore;
}

export default new Vuex.Store<RootState>(store);
