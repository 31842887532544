import { ContentInterface } from "..";

export enum NoteStatus {
	Draft,
	PendingApproval,
	Published,
}

export interface NoteInterface extends ContentInterface {
	title: string;
	text: string;
	date: Date;
	status: NoteStatus;
	sticky: boolean;
}
