import { IsEmail, IsEnum } from "class-validator";

import { BaseDto, UserRole } from "..";

export class AddMemberDto extends BaseDto {
	@IsEmail()
	email!: string;

	@IsEnum(UserRole)
	role!: UserRole;
}

export class AddMembersDto extends BaseDto {
	[index: number]: AddMemberDto;
}
