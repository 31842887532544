import { EntityType } from "@scrinz/dtos";

export function getIconForEntityType(type: EntityType) : string | null {
	switch (type) {
		case EntityType.Group:        return "filter_none";
		case EntityType.Organization: return "account_balance";
		case EntityType.Display:      return "tv";
		default: return null;
	}
}
