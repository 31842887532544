export const SD_TEMPLATE_CONTAINER_TEMPLATES = [
	{ id: "bunny",        image: require("./templates/bunny.png"), fontColor: "dark" },
	{ id: "17-may",       image: require("./templates/17-may.png") },
	{ id: "autum",        image: require("./templates/autum.png") },
	{ id: "barbequing",   image: require("./templates/barbequing.png") },
	{ id: "cake",         image: require("./templates/cake.png") },
	{ id: "cigarette",    image: require("./templates/cigarette.png") },
	{ id: "clear-exits",  image: require("./templates/clear-exits.png") },
	{ id: "close-door",   image: require("./templates/close-door.png") },
	{ id: "dog-backyard", image: require("./templates/dog-backyard.png") },
	{ id: "drainage",     image: require("./templates/drainage.png") },
	{ id: "dugnad",       image: require("./templates/dugnad.png") },
	{ id: "electrical",   image: require("./templates/electrical.png") },
	{ id: "fire",         image: require("./templates/fire.png") },
	{ id: "garage",       image: require("./templates/garage.png") },
	{ id: "grass",        image: require("./templates/grass.png") },
	{ id: "halloween",    image: require("./templates/halloween.png") },
	{ id: "handyman",     image: require("./templates/handyman.png") },
	{ id: "meeting",      image: require("./templates/meeting.png") },
	{ id: "quiet",        image: require("./templates/quiet.png") },
	{ id: "roof-terrace", image: require("./templates/roof-terrace.png") },
	{ id: "summer",       image: require("./templates/summer.png") },
	{ id: "trash",        image: require("./templates/trash.png") },
	{ id: "winter",       image: require("./templates/winter.png") },
	{ id: "warning",      image: require("./templates/warning.png") },
];
