






































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { DisplayOrientation } from "@scrinz/dtos";

import reload from "@/reload";

@Component
export default class DevTools extends Vue {
	orientations = DisplayOrientation;

	@Getter displayId!: string | null;
	@Getter apiKey!: string | null;

	showDevTools = false;
	showDevToolsKeyCombo: number[] = [];
	uninstallVerified = false;

	get orientation(): DisplayOrientation {
		return this.$store.getters.orientation;
	}

	set orientation(orientation: DisplayOrientation) {
		void this.$store.dispatch("setOrientation", orientation);
	}

	async created() {
		document.addEventListener("keydown", e => {
			this.showDevToolsKeyCombo.push(e.keyCode);

			if (this.showDevToolsKeyCombo.length > 4) {
				this.showDevToolsKeyCombo.splice(0, this.showDevToolsKeyCombo.length - 4);
			}

			if (this.showDevToolsKeyCombo.join(",") === "37,37,39,37") {
				this.showDevTools = !this.showDevTools;
			}
		});
	}

	async reload() {
		await reload.reload();
	}

	async uninstall() {
		if (!this.uninstallVerified) {
			this.uninstallVerified = true;

			return;
		}

		await this.$store.dispatch("unsetInstallation");
		this.$router.push("/setup");
	}
}
