import { IsEnum, IsInt, IsOptional, IsString, MaxLength, MinLength } from "class-validator";

import { BaseDto } from "..";
import { EntityType } from "./interfaces";

export class CreateEntityDto extends BaseDto {
	@IsString({ message: "Entity name must be a string." })
	@MinLength(3, { message: "Entity name has to be at least 3 characters long." })
	@MaxLength(255, { message: "Entity name cannot be more than 255 characters long." })
	name!: string;

	@IsEnum(EntityType, { message: "Entity type is not one of accepted values of `EntityType`." })
	type!: EntityType;

	// TODO: To consider, should this be options? Only admins should be able to
	// create root entities. Maybe create an `AdminCreateEntityDto` for those cases?
	// @IsOptional()
	@IsInt({ message: "Entity parentId must be an integer." })
	parentId!: number;

	@IsOptional()
	@IsString({ message: "Entity codeWord must be a string." })
	@MinLength(3, { message: "Entity codeWord has to be at least 3 characters long." })
	@MaxLength(10, { message: "Entity codeWord cannot be more than 10 characters long." })
	codeWord!: string;
}
