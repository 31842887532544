




























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { SdClock } from "../SdClock";
import { SdTransitTimes } from "../SdTransitTimes";
import { SdWeather } from "../SdWeather";

import SdServiceModulesDivider from "./SdServiceModulesDivider.vue";

@Component({
	components: {
		SdClock,
		SdServiceModulesDivider,
		SdTransitTimes,
		SdWeather,
	},
})
export default class SdServiceModules extends Vue {
	@Prop({ default: true })
	showClock!: boolean;

	@Prop({ default: true })
	showTransitTimes!: boolean;

	@Prop({ default: true })
	showWeather!: boolean;

	@Prop({ default: null })
	weather!: any;

	@Prop({ default: null })
	transitTimes!: any;
}
