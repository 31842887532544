import http from "@/http";

export class WeatherService {
	static async getWeather(city: string) {
		try {
			const res = await http.get(`weather/weather`, {
				params: { city },
			});

			if (res && res.status === 200) {
				return res.data;
			}
		} catch (err) {
			// Do nothing yet.
		}
	}
}
