import { IsEnum, IsInt } from "class-validator";

import { BaseDto, UserRole } from "..";
import { UserPermissionDto } from "./user-permission.dto";
import { UserRoleInterface, UserRolePermissions } from "./interfaces";

export class UserRoleDto extends BaseDto<UserRoleInterface> {
	@IsInt() entityId!: number;
	@IsInt() userId!: number;
	@IsEnum(UserRole) role!: UserRole;

	getPermissions(): UserPermissionDto[] {
		if (!this.role) return [];

		return UserRolePermissions[this.role].map(
			perm =>
				new UserPermissionDto({
					entityId: this.entityId,
					userId: this.userId,
					permission: perm,
				}),
		);
	}
}
