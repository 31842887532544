// tslint:disable:no-import-side-effect
import "reflect-metadata";
import "@babel/polyfill";

// Start setting up Vue.
import Vue from "vue";

// Import sentry plugin early to get errors during initiation too.
import "./plugins/sentry";

// Plugin for markdown sections.
import VueMarkdown from "vue-markdown";
Vue.component("vue-markdown", VueMarkdown);

// Plugin for moment dates.
import "./plugins/moment";

// Bootstrap.
import Main from "./Main.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

Vue.filter("capitalize", (str: string) => str.charAt(0).toUpperCase() + str.slice(1));
Vue.filter("uppercase", (str: string) => str.toUpperCase());
// The stripComma filter rounds up from .5 on floats
Vue.filter("stripComma", (number: number) => number.toFixed());

// Bootstrap main component
import { bootstrapVue } from "@scrinz/vue";
void bootstrapVue(Vue, "@scrinz/display-ui", Main, {
	router,
	store,
	additionalConstructorProperties: {
		i18n,
	},
});
