

















































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { DisplayOrientation } from "@scrinz/dtos";
import { SdNote } from "../SdNote";

export enum NoteBoardNotesAnimationDirection {
	Forward = 1,
	Backward = -1,
}

function hexToRgb(hex: string) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
}

@Component({
	components: {
		SdNote,
	},
})
export default class SdNoteBoardNotes extends Vue {
	@Prop({ required: true })
	manifest!: any;

	@Prop({ default: DisplayOrientation.Horizontal, type: [Number] })
	orientation!: DisplayOrientation;

	@Prop({ required: true })
	notes!: any[];

	internalNotes: any[] = [];
	regularNotes: any[] = [];
	stickyNotes: any[] = [];

	timeout!: any;
	rotationTiming = 20000;

	get classes() {
		return {
			"sd-note-board-notes": true,
			"sd-note-board-notes--horizontal":
				this.orientation === DisplayOrientation.Horizontal,
			"sd-note-board-notes--vertical":
				this.orientation === DisplayOrientation.Vertical,
		};
	}

	get stickyNotesAlt() {
		return this.notes.filter(note => note.sticky === true);
	}

	get shadowStyles() {
		const styles: any = {};

		const bgColor =
			this.manifest.entity.noteBoardBackgroundColor ||
			this.manifest.organization.noteBoardBackgroundColor;
		const rgb = hexToRgb(bgColor);

		if (rgb) {
			styles.background = `linear-gradient(
				to top,
				rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1) 0%,
				rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0) 100%
			)`;
		}

		return styles;
	}

	getWrapperElement() {
		return this.$refs.wrapper as HTMLElement;
	}

	getNotesElements() {
		return this.$refs.notes as Vue[];
	}

	getTotalVerticalSpace() {
		const container = (this.$refs.container as Vue)?.$el;

		return [container.clientHeight + 10, container.clientHeight + 10].splice(
			0,
			this.orientation === DisplayOrientation.Vertical ? 2 : 1
		);
	}

	getNotesHeights() {
		const notes = this.getNotesElements();

		if (!notes) return [];

		return notes.map(n => (n.$el.clientHeight as number) + 10);
	}

	isOverflowing() {
		const space = this.getTotalVerticalSpace();
		const heights = this.getNotesHeights();

		while (heights.length > 0) {
			if (space.length === 0) return true;

			if (space[0] - heights[0] < 0) {
				space.shift();
				continue;
			}

			space[0] = space[0] - heights[0];
			heights.shift();
		}

		return false;
	}

	zIndexForIndex(index: number) {
		if (!this.internalNotes) return 0;

		return this.internalNotes.length - index;
	}

	@Watch("notes", { immediate: true })
	async onNotesChange(oldNotes: any, newNotes: any) {
		console.log("Notes", this.notes, oldNotes, newNotes);

		this.internalNotes = [...this.notes];

		this.internalNotes.forEach((note: any) => {
			console.log("Note", note);

			if (note.sticky === true) {
				this.stickyNotes.push(note);
			} else {
				this.regularNotes.push(note);
			}
		});

		await this.$nextTick();

		console.log("Sticky notes", this.stickyNotes);
		console.log("Sticky notes alt", this.stickyNotesAlt);
		console.log("Regular notes", this.regularNotes);

		if (this.isOverflowing()) {
			await this.primeNextRotation();
		}
	}

	async primeNextRotation() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		const fn = async () => {
			await this.rotate();
		};

		this.timeout = setTimeout(fn, this.rotationTiming);
	}

	async rotate() {
		const regularFirst = this.regularNotes.shift();

		await this.$nextTick();

		this.regularNotes.push(regularFirst);

		await this.primeNextRotation();
	}
}
