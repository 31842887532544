import http from "@/http";

export class ContentService {
	static async getContent(
		contentSlot: "note"|"template"|"adsSmall"|"adsLarge",
		displayId: string,
	) {
		try {
			const res = await http.get(`display/${displayId}/${contentSlot}`);

			if (res && res.status === 200) {
				return res.data;
			}
		} catch (err) {
			// TODO: Retry or something
		}
	}
}
