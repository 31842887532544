import { DisplayOrientation } from "@scrinz/dtos";

export class DebugData {
	orientation!: DisplayOrientation;
	scale!: number;
}

export interface DebugState {
	debug: Partial<DebugData>;
}
