




import { Component, Prop, Vue } from "vue-property-decorator";
import QRCode from "qrcode";

@Component
export default class SdQrCode extends Vue {
	qrSrc: string = "";

	@Prop()
	qrContent!: string;

	async generateQRCode() {
		if (this.qrContent) {
			this.qrSrc = await QRCode.toDataURL(this.qrContent, {
				errorCorrectionLevel: "M",
				type: "image/webp",
				margin: 2,
				scale: 3,
			});
		}

		return this.qrSrc;
	}

	mounted() {
		this.generateQRCode();
	}
}
