export enum UserRole {
	Owner,
	Administrator,
	Moderator,
}

export enum UserPermission {
	// Owner
	DeleteEntity = "delete_entity",

	// Administrator
	CreateEntity = "create_entity",
	EditEntity = "edit_entity",

	// Moderator
	ModerateContent = "moderate_content",
	Read = "read",
}

export interface UserRoleInterface {
	id?: number;
	entityId: number;
	userId: number;
	role: UserRole;
}

export interface UserPermissionInterface {
	entityId: number;
	userId: number;
	permission: UserPermission;
}

export interface UserRolePermissionsInterface {
	[role: number]: UserPermission[];
}

export const UserRolePermissions: UserRolePermissionsInterface = {};

UserRolePermissions[UserRole.Moderator] = [UserPermission.ModerateContent, UserPermission.Read];

UserRolePermissions[UserRole.Administrator] = [
	...UserRolePermissions[UserRole.Moderator],
	UserPermission.CreateEntity,
	UserPermission.EditEntity,
];

UserRolePermissions[UserRole.Owner] = [...UserRolePermissions[UserRole.Administrator], UserPermission.DeleteEntity];
