









































































	import { Component, Prop, Watch, Vue } from "vue-property-decorator";
	import getenv from "getenv.ts";
	import moment from "moment-timezone";

import { API_PATH } from "@/constants";

	import http from "@/http";
	import { orientationSizeStyle } from "@/utils";

	import DetectNetwork from "v-offline";
	import { DisplayOrientation } from "@scrinz/dtos";
	import AbsoluteLayout from "@/layouts/AbsoluteLayout.vue";
	import CenteredCardLayout from "@/layouts/CenteredCard.vue";
	import { SdNoteBoard, SdServiceModules, SdSlider } from "@scrinz/components";

import { ContentService } from "@/services/ContentService";
import { TransitTimesService } from "@/services/TransitTimesService";
import { WeatherService } from "@/services/WeatherService";

export type ContentSlot = "note"|"template"|"adsSmall"|"adsLarge";

	export enum DisplayRendererState {
		Loading,
		Offline,
		Rendered,
		Error,
	}

	@Component({
		components: {
			AbsoluteLayout,
			CenteredCardLayout,
			DetectNetwork,
			SdNoteBoard,
			SdServiceModules,
			SdSlider,
		},
	})
	export default class Display extends Vue {
		states = DisplayRendererState;

		@Prop({ required: true })
		displayId!: string;

		@Prop({ default: null, type: [Number] })
		orientationOverride!: DisplayOrientation | null;

		isOnline: boolean = navigator.onLine || false;

		state: DisplayRendererState = DisplayRendererState.Loading;
		loadingDots = "...";
		loadingDotsTimeout: any;
		errorRetryCountdown = 10;
		errorRetryCountdownTimeout: any;

		manifest: any = false;
		adsSmall: any[]|boolean = false;
		adsLarge: any[]|boolean = false;
		templates: any[]|boolean = false;

		content: { [slot: string]: any } = {};
		contentLoaded: { [slot: string]: boolean } = {};
		transitTimes: any = null;
		weather: any = null;

		private _transitTimesRefreshTimeout: number | undefined;
		private _weatherRefreshTimeout: number | undefined;

		get assetsBasePath() {
			return `${API_PATH}/assets/`;
		}

		get city() {
			return this.manifest.entity.city || this.manifest.organization.city;
		}

		get orientation() {
			if (this.orientationOverride !== null) return this.orientationOverride;

			return this.manifest.orientation || DisplayOrientation.Horizontal;
		}

		get displayRendererStyles() {
			return {
				...orientationSizeStyle(this.orientation),
			};
		}

		get bannerFallbackText() {
			return this.manifest.entity.bannerFallbackText
				|| this.manifest.organization.bannerFallbackText;
		}

		get bannerTemplateVariables() {
			const smsPhoneNumber = getenv.string("VUE_APP_SMS_NUMBER");

			return {
				sms: {
					codeWord: this.manifest.codeWord,
					number: smsPhoneNumber,
					numberNoSpace: smsPhoneNumber.replace(/\s/g, ""),
				},

				organization: {
					name: this.manifest.organization.name,
					codeWord: this.manifest.organization.codeWord,
					street: this.manifest.organization.street,
					city: this.manifest.organization.city,
					zip: this.manifest.organization.zip,
				},

				entity: {
					name: this.manifest.entity.name,
					street: this.manifest.entity.street,
					city: this.manifest.entity.city,
					zip: this.manifest.entity.zip,
				},
			};
		}

		get smsNumber() {
			return getenv.string("VUE_APP_SMS_NUMBER");
		}

		get noteBoardLogo() {
			if (["85oDnjvDg1"].includes(this.displayId)) {
				return "malmberg";
			}

			return "default";
		}

		async onNetworkConditionDetected(online: boolean) {
			this.isOnline = online;

			if (online) {
				await this.setupData();
			}
		}

		@Watch("displayId", { immediate: true })
		async setupData() {
			this.state = DisplayRendererState.Loading;
			this.clearErrorRetryCountdown();
			this.tickLoadingDots();

			if (!this.isOnline) {
				this.state = DisplayRendererState.Offline;

				return;
			}

			try {
				const manifest = await http.get(`display/${this.displayId}/manifest`);

				if (manifest.data) {
					this.manifest = manifest.data;

					if (this.manifest.timeZone) {
						moment.tz.setDefault(this.manifest.timeZone);
					}
				}

				this.$forceUpdate();
			} catch (err) {
				this.state = DisplayRendererState.Error;
				this.tickErrorRetryCountdown();
			}
		}

		@Watch("manifest")
		onManifestChange() {
			this._updateContent();
			this._updateTransitTimes();
			this._updateWeather();
		}

		tickLoadingDots() {
			if (this.loadingDotsTimeout) {
				clearTimeout(this.loadingDotsTimeout);
			}

			this.loadingDotsTimeout = setTimeout(() => {
				this.loadingDots = this.loadingDots.length === 3 ? "" : `${this.loadingDots}.`;
				this.tickLoadingDots();
			}, 1000); // tslint:disable-line
		}

		clearErrorRetryCountdown() {
			if (this.errorRetryCountdownTimeout) {
				clearTimeout(this.errorRetryCountdownTimeout);
			}
		}

		tickErrorRetryCountdown() {
			this.clearErrorRetryCountdown();

			if (this.errorRetryCountdown === 0) {
				this.state = DisplayRendererState.Loading;
				this.errorRetryCountdown = 30; // tslint:disable-line
				void this.setupData();
			} else {
				this.errorRetryCountdownTimeout = setTimeout(() => {
					this.errorRetryCountdown -= 1;
					this.tickErrorRetryCountdown();
				}, 1000); // tslint:disable-line
			}
		}

		private async _updateContent() {
			await Promise.all([
				this._updateContentSlot("note"),
				this._updateContentSlot("template"),
				this._updateContentSlot("adsSmall"),
				this._updateContentSlot("adsLarge"),
			]);
		}

		private async _updateContentSlot(slot: ContentSlot) {
			this.content[slot] = await ContentService.getContent(slot, this.displayId);
			this.contentLoaded[slot] = true;
		}

		private async _updateWeather() {
			// Clear any previously set timeout
			clearTimeout(this._weatherRefreshTimeout);

			// Get new weather data
			this.weather = await WeatherService.getWeather(this.city);

			// Set timeout to refresh weather again in x minutes
			this._weatherRefreshTimeout = window.setTimeout(
				this._updateWeather.bind(this),
				1000 * 60 * 15, // 15 minutes
			);
		}

		private async _updateTransitTimes() {
			clearTimeout(this._transitTimesRefreshTimeout);

			const departures = await TransitTimesService.getDepartures(this.manifest.transitStops);

			if (departures && departures instanceof Array) {
				this.transitTimes = departures.map(departure => {
					return {
						...departure,
						label: this._getLabelForTransitStop(departure.id),
					};
				});
			}

			this._transitTimesRefreshTimeout = window.setTimeout(
				this._updateTransitTimes.bind(this),
				1000 * 60 * 1, // One minute
			);
		}

		private _getLabelForTransitStop(id: string) {
			for (const stop of this.manifest.transitStops) {
				if (stop.stopId === id) return stop.label;
			}

			return "";
		}
	}
