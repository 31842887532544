import { IsEnum, IsInt, IsOptional, IsPositive, IsString, MaxLength, MinLength } from "class-validator";

import { BaseDto } from "..";
import { EntityType } from "./interfaces";

export class EntityDto extends BaseDto {
	@IsOptional()
	@IsInt({ message: "Entity id is not a number" })
	@IsPositive()
	id!: number;

	@IsOptional()
	@IsInt()
	@IsPositive()
	parentId!: number;

	@IsOptional()
	@IsEnum(EntityType)
	type!: EntityType;

	@IsOptional()
	@IsString()
	codeWord!: string;

	@IsOptional()
	@IsString()
	apiKey!: string;

	@IsOptional()
	@IsString()
	@MinLength(3)
	@MaxLength(255)
	name!: string;

	@IsOptional()
	@IsString()
	@MaxLength(255)
	displayInformation!: string;

	@IsOptional()
	@IsString()
	street!: string;

	@IsOptional()
	@IsString()
	zip!: string;

	@IsOptional()
	@IsString()
	city!: string;

	@IsOptional()
	@IsEnum(EntityDto, { each: true })
	children!: EntityDto[];

	@IsOptional()
	@IsString()
	noteBoardBackgroundColor!: string;
}
