import { IsString } from "class-validator";

import { BaseDto } from "..";

export class RegisterDisplayRequestDto extends BaseDto {
	@IsString() displayId!: string | number;
	@IsString() apiKey!: string;
}

export class RegisterDisplayResponseDto extends BaseDto {
	@IsString() displayRegistrationHandle!: string;
}
