
























import { Component, Prop, Vue } from "vue-property-decorator";
import moment, { Moment } from "moment-timezone";

import SdNoteIcon from "../SdNoteIcon";
import SdQrCode from "../SdQrCode";

@Component({
	components: {
		SdNoteIcon,
		SdQrCode,
	},
	filters: {
		formatDate(date: Date | Moment | number | string) {
			const now = moment();
			const newDate = moment(date);

			// tslint:disable-next-line:no-magic-numbers
			if (now.diff(newDate, "days") >= 30) {
				return newDate.format("D. MMMM YYYY");
			}

			return newDate.fromNow();
		},
	},
})
export default class SdNote extends Vue {
	/**
	 * **Deprecated** A JSON object defining the note.
	 * @deprecated
	 */
	@Prop()
	note!: any;

	/**
	 * A Date object or string representation of a date to use as the timestamp
	 * of the note.
	 */
	@Prop({ default: () => new Date(), type: [Date, Object, Number, String] })
	date!: Date | Moment | number | string;

	/**
	 * The icon to display in the note.
	 */
	@Prop()
	icon!: string;

	/**
	 * The background color to display the icon on top of.
	 */
	@Prop()
	iconBackgroundColor!: string;

	/**
	 * A raw HTML string to be used as the body contents of the note.
	 */
	@Prop()
	htmlBody!: string;

	@Prop()
	qrContent!: string;
}
