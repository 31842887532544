import { UserRole } from "@scrinz/dtos";

export function getRoleName(role: UserRole): string | null {
	switch (role) {
		case UserRole.Administrator: return "Administrator";
		case UserRole.Moderator:     return "Moderator";
		case UserRole.Owner:         return "Owner";
		default: return null;
	}
}
