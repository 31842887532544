












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { DisplayOrientation } from "@scrinz/dtos";
import { orientationSizeStyle } from "@/utils";

@Component
export default class PreviewScroller extends Vue {
	@Prop({ required: true, type: [Number, String] })
	scalePercentage!: number | string;

	@Prop({ default: null, type: [Number] })
	orientationOverride!: DisplayOrientation | null;

	get orientation() {
		if (this.orientationOverride !== null) return this.orientationOverride;

		return this.$store.getters.orientation || DisplayOrientation.Horizontal;
	}

	get scale() {
		let percentage = this.scalePercentage;

		if (typeof percentage === "string") {
			percentage = parseInt(percentage, 10);
		}

		return percentage;
	}

	get wrapperStyle() {
		return {
			...orientationSizeStyle(this.orientation),
		};
	}

	get scaleStyle() {
		return {
			transform: `scale(${this.scale / 100})`,
		};
	}

	async mounted() {
		const scroller = this.$refs["scroller"] as Element;

		const heightDiff = scroller.scrollHeight - scroller.clientHeight;
		const widthDiff = scroller.scrollWidth - scroller.clientWidth;

		scroller.scrollTop = heightDiff / 2;
		scroller.scrollLeft = widthDiff / 2;
	}
}
