
















import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import uniqBy from "lodash/uniqBy";

import { DisplayOrientation } from "@scrinz/dtos";

import SdNoteBoardInfo from "./SdNoteBoardInfo.vue";
import SdNoteBoardNotes from "./SdNoteBoardNotes.vue";

@Component({
	components: { SdNoteBoardInfo, SdNoteBoardNotes },
})
export default class NoteBoard extends Vue {
	@Prop({ required: true })
	manifest!: any;

	@Prop()
	smsNumber!: string;

	@Prop({ default: DisplayOrientation.Horizontal, type: [Number] })
	orientation!: DisplayOrientation;

	@Prop({ default: () => [], type: Array })
	notes!: any[];

	@Prop()
	logo!: string;

	internalNotes: any[] = [];
	_tickTimeout!: any;

	get filtered() {
		if (!this.internalNotes) return [];

		// added to make sure no duplicate notes are shown
		const uniqueNotes = uniqBy(this.internalNotes, "id");
		return uniqueNotes.filter(n => n._show === true);
	}

	get noteBoardStyles() {
		return {
			background:
				this.manifest.entity.noteBoardBackgroundColor ||
				this.manifest.organization.noteBoardBackgroundColor,
		};
	}

	get sorted() {
		return this.filtered.sort((a, b) => {
			const av = a.date.valueOf();
			const bv = b.date.valueOf();

			if (av > bv) return -1;
			if (av < bv) return 1;

			if (av === bv) {
				if (a.id > b.id) return -1;
				if (a.id < b.id) return 1;
			}

			return 0;
		});
	}

	@Watch("notes", { immediate: true })
	onNotesChange() {
		const now = new Date().valueOf();

		this.internalNotes = this.notes.map((note: any) => {
			note.date = new Date(note.date);
			note._show = true;

			if (note.date.valueOf() > now) {
				note._show = false;
			}

			if (note.end) {
				note.end = new Date(note.end);
			}

			return note;
		});

		this.tickUpdateNotes();
	}

	tickUpdateNotes() {
		clearTimeout(this._tickTimeout);

		const now = new Date().valueOf();

		this.internalNotes.forEach(note => {
			if (note._show === false && note.date.valueOf() < now) {
				note._show = true;
			}

			if (note._show === true && note.end && note.end.valueOf() < now) {
				note._show = false;
			}
		});

		this._tickTimeout = setTimeout(this.tickUpdateNotes.bind(this), 1000);
	}
}
