import { IsOptional } from "class-validator";

import { EntityType } from "./interfaces";
import { CreateEntityDto } from "./create-entity.dto";

export class UpdateEntityDto extends CreateEntityDto {
	@IsOptional() name!: string;
	@IsOptional() type!: EntityType;
	@IsOptional() parentId!: number;
}
